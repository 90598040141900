import { FederationRuntimePlugin } from "@module-federation/enhanced/runtime";

/*
 * A Module Federation plugin that overrides the default 'shared-strategy' from "version-first" to "loaded-first".
 * If shared packages are specified through Module Federation, the default strategy would have Module Federation try
 * to load every remote application and examine which versions of  the shared packages are being requested before
 * deciding which version to load into the MFE. However, if a remote application were to be down, Module Federation
 * is throwing an uncatchable error from the default package sharing strategy that is bypassing our Error Fallbacks
 * and is taking down the whole MFE. https://github.com/module-federation/core/issues/2672#issuecomment-2235190899
 */
const sharedStrategy: () => FederationRuntimePlugin = () => ({
    name: "shared-strategy-plugin",
    beforeInit(args) {
        const { userOptions } = args;
        const { shared } = userOptions;
        if (shared) {
            Object.keys(shared).forEach((sharedKey) => {
                const sharedConfigs = shared[sharedKey];
                const arraySharedConfigs = Array.isArray(sharedConfigs) ? sharedConfigs : [sharedConfigs];
                arraySharedConfigs.forEach((s) => {
                    s.strategy = "loaded-first";
                });
            });
        }
        return args;
    },
});

export default sharedStrategy;
